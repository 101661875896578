
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CommonDateType, CommonHtmlType } from "@/core/directive/type/common";
import { ApiBase, ApiInspection } from "@/core/api";
import {
  formatDateTime,
  modalShowListener,
} from "@/core/directive/function/common";
import { TaggingItem } from "@/core/directive/interface/common";
import {
  getInspectionSecondReasonBgStyleMap,
  getInspectionSecondReasonDescriptionMap,
  getInspectionSecondReasonSvgMap,
  getInspectionSecondReasonSvgStyleMap,
  getInspectionSecondReasonTitleMap,
} from "@/core/directive/function/inspection";
import {
  InspectionDetailInspectionItem,
  inspectionDetailInspectionItemData,
} from "@/core/directive/interface/inspection";
import { User } from "@/store/modules/AuthModule";
import store from "@/store";

export default defineComponent({
  name: "warehouse-management-inspection-waiting-approve-modal",
  props: {
    itemData: {
      type: Object as () => InspectionDetailInspectionItem,
      default: () => {
        return Object.assign({}, inspectionDetailInspectionItemData);
      },
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const currentInstance: any = getCurrentInstance();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const secondTimeInspectionRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const userInfo = ref<User>();

    const formData = ref({
      images: [] as any[],
      radio: "",
      notes: "",
    });

    const rules = ref({
      radio: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      result: [] as TaggingItem[],
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    userInfo.value = store.getters.currentUser;

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const getShowInfo = async () => {
      const { data } = await ApiInspection.getInspectionWarehouseFailImage({
        id: props.itemData.id,
      });
      if (data.code == 0) {
        formData.value.images = data.data.images;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["inspections_2nd_inspection_result"],
      });
      if (data.code == 0) {
        options.value.result =
          data.data.inspections_2nd_inspection_result.items;
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      setSubmitAttribute();
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
          setSubmitAttribute(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          submitRadio();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitRadio = async () => {
      loading.value = true;
      setSubmitAttribute();
      const { data } =
        await ApiInspection.updateInspectionSecondQualityInspection({
          id: props.itemData.id,
          second_inspection_result: formData.value.radio,
          second_inspection_notes: formData.value.notes,
        });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          emit("update-list");
          hideModal(secondTimeInspectionRef.value);
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const handleDiscard = () => {
      hideModal(secondTimeInspectionRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        getFormInfo();
        formData.value.radio =
          props.itemData.second_inspection_result === 0
            ? ""
            : String(props.itemData.second_inspection_result);
      }, 0);
    };

    const customFormatDateTime = (
      date: CommonDateType,
      format = "YYYY-MM-DD HH:mm"
    ) => {
      return formatDateTime(date, format);
    };

    const getImages = computed(() => {
      let arr: string[] = [];
      formData.value.images.map((item) => {
        arr.push(item.url);
      });
      return arr;
    });

    const handlePreview = (arr: string[], index: number) => {
      currentInstance.proxy.emitter.emit("preview-images", {
        sources: arr,
        sourcesIndex: index,
        sourcesSlide: index + 1,
      });
    };

    const previewImages = (index: number) => {
      handlePreview(getImages.value, index);
    };

    onMounted(() => {
      modalShowListener(secondTimeInspectionRef.value, () => {
        init();
      });
      modalHideListener(secondTimeInspectionRef.value, () => {
        resetForm();
      });
      getTaggingData();
    });

    return {
      t,
      getInspectionSecondReasonTitleMap,
      getInspectionSecondReasonDescriptionMap,
      getInspectionSecondReasonSvgMap,
      getInspectionSecondReasonSvgStyleMap,
      getInspectionSecondReasonBgStyleMap,
      formRef,
      submitButtonRef,
      submitButton,
      secondTimeInspectionRef,
      props,
      loading,
      formData,
      rules,
      options,
      userInfo,
      handleDiscard,
      resetForm,
      submit,
      customFormatDateTime,
      previewImages,
    };
  },
});
