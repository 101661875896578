
import {
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  getCurrentInstance,
} from "vue";
import { ApiInspection } from "@/core/api";
import {
  InspectionDetailInspectionItem,
  inspectionDetailInspectionItemData,
  InspectionInfo,
} from "@/core/directive/interface/inspection";
import JwtService from "@/core/services/JwtService";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import FsLightbox from "fslightbox-vue/v3";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { modalShowListener } from "@/core/directive/function/common";
import { InspectionReason } from "@/core/directive/type/inspection";
import { ElNotification, ElUpload } from "element-plus";

interface File {
  file_upload_record_id: number;
  size: number;
  url: string;
}

export default defineComponent({
  name: "inspection-waiting-approve-image-modal",
  props: {
    itemData: {
      type: Object as () => InspectionDetailInspectionItem,
      default: () => {
        return Object.assign({}, inspectionDetailInspectionItemData);
      },
    },
    files: { type: Array as () => File[], required: true },
    infoData: { type: Object, required: true },
    filesId: { type: Array as () => number[], required: true },
  },
  components: {
    FsLightbox,
  },
  emits: ["update-list", "reset-form", "submit-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const inspectionWaitingApproveImageModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const inspectionInfo = ref<InspectionInfo>();
    const uploadRef = ref<InstanceType<typeof ElUpload>>();
    const fileList = ref<Array<File>>([]);
    const uploadList = ref<Array<any>>([]);
    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });
    const uploadLoading = ref<boolean>(false);
    const reasonErrorMessage = ref<string>("");
    const uploadErrorMessage = ref<string>("");

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      file_upload_record_id: [] as number[],
    });

    const rules = ref({});

    const options = ref({
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
      printLoading: false,
    });

    const uploadWarningNumber = (number: number) => {
      ElNotification({
        title: t("salesOrderOverview.warning"),
        message: t("inspection.uploadImageTips", [number]),
        type: "warning",
        customClass: "warning",
        duration: 0,
      });
    };

    const onUploadChange = async (file, files) => {
      uploadList.value.push(file);
      const params = new FormData();
      let fileArr: any[] = [];
      uploadList.value.map((item) => {
        if (item.raw) {
          fileArr.push(item);
          params.append("file[]", item.raw, item.raw.name);
        }
      });
      debounceUpload(params, fileArr);
    };

    const uploadImages = async (params, files: any[]) => {
      uploadLoading.value = true;
      const { data } = await ApiInspection.uploadInspectionWarehouseImage(
        params
      );
      uploadLoading.value = false;
      if (data.code === 0) {
        let fileIds: Array<number> = [],
          arr: any[] = [],
          url: string[] = [],
          repeatNumber = 0;
        data.data.data.map((item, index) => {
          if (url.indexOf(item.url) === -1) {
            url.push(item.url);
            if (
              formData.value.file_upload_record_id.indexOf(
                item.file_upload_record_id
              ) === -1
            ) {
              fileIds.push(item.file_upload_record_id);
              arr.push(item);
            } else {
              repeatNumber++;
              uploadRef.value?.handleRemove(files[index], files[index].raw);
            }
          }
        });
        if (repeatNumber > 0) {
          uploadWarningNumber(repeatNumber);
        }
        fileList.value.push(...arr);
        formData.value.file_upload_record_id.push(...fileIds);
        uploadList.value = [];
      } else {
        showServerErrorMsg(data);
        uploadList.value = [];
        files.forEach((item) => {
          uploadRef.value?.handleRemove(item, item.raw);
        });
      }
    };

    const debounceUpload = _.debounce(uploadImages, 100);

    const onUploadError = async (error, file, files) => {
      console.log(error, file, files);
    };

    const handleRemove = (file) => {
      fileList.value.forEach((item, index) => {
        if (item.file_upload_record_id == file.file_upload_record_id) {
          fileList.value.splice(index, 1);
          uploadList.value.splice(index, 1);
        }
      });
      let fileIds: Array<number> = [];
      fileList.value.forEach((item) => {
        fileIds.push(item.file_upload_record_id);
      });
      formData.value.file_upload_record_id = fileIds;
    };

    const handlePreview = (file) => {
      let arr: string[] = [],
        number = 0;
      fileList.value.forEach((item, index) => {
        arr.push(item.url);
        if (item.file_upload_record_id == file.file_upload_record_id) {
          number = index;
        }
      });
      options.value.sources = arr;
      options.value.sourcesIndex = number;
      options.value.sourcesSlide = number + 1;
      options.value.toggler = !options.value.toggler;
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          ApiInspection.saveInspectionPassedImage({
            id: props.itemData.id,
            file_upload_record_id: formData.value.file_upload_record_id,
          })
            .then(({ data }) => {
              loading.value = false;
              if (data.code == 0) {
                updateInfo(true);
                handleDiscard();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(inspectionWaitingApproveImageModalRef.value);
    };

    const updateInfo = (isflag = false) => {
      const data = {
        infoData: formData.value,
        files: fileList.value,
        fileUploads: uploadList.value,
        isflag: isflag,
      };
      emit("update-list", data);
    };

    const resetForm = () => {
      formData.value = {
        file_upload_record_id: [],
      };
      fileList.value = [];
      uploadList.value = [];
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        // formData.value = props.infoData as any;
        fileList.value = props.files;
        formData.value.file_upload_record_id = props.filesId;
      }, 500);
    };

    onBeforeMount(() => {
      // getCountryData();
    });

    onMounted(() => {
      modalShowListener(inspectionWaitingApproveImageModalRef.value, () => {
        init();
      });
      modalHideListener(inspectionWaitingApproveImageModalRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      InspectionReason,
      props,
      loading,
      uploadLoading,
      formData,
      uploadRef,
      rules,
      options,
      formRef,
      inspectionInfo,
      inspectionWaitingApproveImageModalRef,
      submitButton,
      fileList,
      uploadHeaders,
      reasonErrorMessage,
      uploadErrorMessage,
      submit,
      handleDiscard,
      resetForm,
      onUploadChange,
      onUploadError,
      handlePreview,
      handleRemove,
    };
  },
});
