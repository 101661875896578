
import {
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  watch,
  computed,
  getCurrentInstance,
} from "vue";
import { ApiBase, ApiInspection } from "@/core/api";
import { StepperComponent } from "@/assets/ts/components";
import { previewOrderImages } from "@/core/directive/function/order";
import { ProductItemImage } from "@/core/directive/interface/order";
import {
  InspectionDetailInspectionItem,
  inspectionDetailInspectionItemData,
  InspectionInfo,
} from "@/core/directive/interface/inspection";
import JwtService from "@/core/services/JwtService";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import FsLightbox from "fslightbox-vue/v3";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { modalShowListener } from "@/core/directive/function/common";
import { TaggingItem } from "@/core/directive/interface/common";
import ElInput from "element-plus/es/components/input";
import {
  InspectionReason,
  InspectionStage,
} from "@/core/directive/type/inspection";
import { ElNotification, ElUpload } from "element-plus";
import { PrinterConfigTaskDocumentContent } from "@/core/directive/interface/print";
import InspectionStepProductImageModal from "./inspectionStepProductImageModal.vue";
import {
  checkProductBarcode,
  setProductInfo,
} from "@/core/directive/function/inspection";

interface File {
  file_upload_record_id: number;
  size: number;
  url: string;
}

export default defineComponent({
  name: "inspection-steps-modal",
  props: {
    identifierCode: { type: String, required: false },
    itemData: {
      type: Object as () => InspectionDetailInspectionItem,
      default: () => {
        return Object.assign({}, inspectionDetailInspectionItemData);
      },
    },
    tableData: {
      type: Array as () => InspectionDetailInspectionItem[],
      default: () => {
        return [];
      },
    },
  },
  components: {
    FsLightbox,
    InspectionStepProductImageModal,
  },
  emits: ["update-list", "reset-form", "update-detail", "print-preview"],
  setup(props, { emit }) {
    const currentInstance: any = getCurrentInstance();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const inspectionStepsModalRef = ref<null | HTMLElement>(null);
    const inspectionStepperRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const { t } = useI18n();
    const inspectionInfo = ref<InspectionInfo | null>(null);
    const activeStepIndex = ref<number>(1);
    //const uploadAction = ApiInspection.uploadInspectionWarehouseImage();
    const uploadRef = ref<InstanceType<typeof ElUpload>>();
    const fileList = ref<Array<File>>([]);
    const uploadList = ref<Array<any>>([]);
    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });
    const uploadLoading = ref<boolean>(false);
    const reasonErrorMessage = ref<string>("");
    const uploadErrorMessage = ref<string>("");
    const identifierCodeRef = ref<InstanceType<typeof ElInput>>();
    const barcode = ref<string>("");
    const barcodeInput = ref<null | HTMLFormElement>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      identifier_code: "",
      ean: "",
      origin_country: "",
      reason: 0,
      file_upload_record_id: [] as number[],
    });

    const rules = ref({});

    const options = ref({
      originCountry: [] as TaggingItem[],
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
      printLoading: false,
    });

    watch(
      () => formData.value.identifier_code,
      (newCode, oldCode) => {
        getInspectionInfo(newCode);
      }
    );

    watch(
      () => formData.value.ean,
      (newCode, oldCode) => {
        formData.value.ean = newCode.split("#200#").join("");
      }
    );

    watch(
      () => barcode.value,
      (newVal) => {
        console.log("barcode: " + newVal);
        if (newVal == "#200#") {
          debounceScanCode();
        }
      }
    );

    // watch(
    //   () => props.identifierCode,
    //   (newCode, oldCode) => {
    //     getInspectionInfo(newCode);
    //   }
    // );

    watch(
      () => activeStepIndex.value,
      (newIndex) => {
        getRules(newIndex);
      }
    );

    const productImageSubmitForm = () => {
      goToStep(4);
      passedTip();
      emit("update-list");
      emit("update-detail");
    };

    const productImageUpdateList = (data: any) => {
      formData.value = data.infoData;
      fileList.value = data.files;
      // uploadList.value = data.fileUploads;
    };

    const closeProductImageModal = () => {
      if (activeStepIndex.value == 2) {
        barcodeInputFocus();
      }
    };

    const disabledContinue = computed(() => {
      let flag = true;
      const index = props.tableData.findIndex(
        (item) => item.identifier_code === formData.value.identifier_code
      );
      if (
        index !== -1 &&
        inspectionInfo.value?.inspection_stage ===
          InspectionStage.FirstTimeInspection
      ) {
        flag = false;
      }
      return flag;
    });

    const isShowPassed = computed(() => {
      let flag = true;
      if (activeStepIndex.value === 2) {
        if (props.itemData.is_additional === 1) {
          flag = false;
        } else {
          const index = props.tableData.findIndex(
            (item) => item.identifier_code === formData.value.identifier_code
          );
          if (
            index !== -1 &&
            inspectionInfo.value?.inspection_stage ===
              InspectionStage.FirstTimeInspection
          ) {
            if (props.tableData[index].is_additional === 1) {
              flag = false;
            }
          }
        }
      }
      return flag;
    });

    const getPrintedSheet = async () => {
      const params = {
        identifier_code: [inspectionInfo?.value?.identifier_code],
      };
      // options.value.printLoading = true;
      // const { data } = await ApiInspection.printedSheet({
      //   identifier_code: [inspectionInfo?.value?.identifier_code],
      // });
      // options.value.printLoading = false;
      // if (data.code === 0) {
      //   let contents: PrinterConfigTaskDocumentContent[] = [];
      //   data.data.product.map((item) => {
      //     contents.push({
      //       templateURL: data.data.templateURL,
      //       signature:
      //         "19d6f7759487e556ddcdd3d499af087080403277b7deed1a951cc3d9a93c42a7e22ccba94ff609976c5d3ceb069b641f541bc9906098438d362cae002dfd823a8654b2b4f655e96317d7f60eef1372bb983a4e3174cc8d321668c49068071eaea873071ed683dd24810e51afc0bc925b7a2445fdbc2034cdffb12cb4719ca6b7",
      //       data: item,
      //     });
      //   });
      //   printProduct(contents);
      // } else {
      //   showServerErrorMsg(data);
      // }
      emit("print-preview", params);
    };

    const printProduct = (contents: PrinterConfigTaskDocumentContent[]) => {
      currentInstance.proxy.emitter.emit("all-print", contents);
    };

    const getRules = (index) => {
      if (index == 1) {
        rules.value = {
          identifier_code: [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ],
        };
      } else if (index == 2) {
        rules.value = {
          ean: [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ],
          origin_country: [
            {
              required: true,
              message: t("common.isRequired"),
              trigger: "change",
            },
          ],
        };
      } else {
        rules.value = {};
      }
    };

    const errorTip = () => {
      ElNotification({
        title: "Already Inspected",
        message: formData.value.identifier_code,
        type: "error",
        customClass: "error",
      });
    };

    const inspectedTip = () => {
      ElNotification({
        title: "This goods had been already inspected.",
        message: formData.value.identifier_code,
        type: "warning",
        customClass: "warning",
      });
    };

    const warningTip = () => {
      ElNotification({
        title: "Failed +1",
        message: formData.value.identifier_code,
        type: "warning",
        customClass: "warning",
      });
    };

    const passedTip = () => {
      ElNotification({
        title: "Passed +1",
        message: formData.value.identifier_code,
        type: "success",
        customClass: "success",
      });
    };

    const getInspectionInfo = async (code) => {
      const isProductBarcode = checkProductBarcode.value(code);
      if (isProductBarcode) {
        const productInfo = setProductInfo(code);
        formData.value.identifier_code = productInfo.identification_code;
      } else if (code.length !== 9) {
        return;
      }
      loading.value = true;
      const { data } = await ApiInspection.getInspectionDetail({
        identifier_code: code,
      });
      loading.value = false;
      if (data.code == 0) {
        inspectionInfo.value = data.data;
        if (data.data.gtin_newest) {
          formData.value.ean = data.data.gtin_newest;
        }
        if (data.data.origin_country_newest) {
          formData.value.origin_country = data.data.origin_country_newest;
        }
        if (
          data.data.inspection_stage !== InspectionStage.FirstTimeInspection
        ) {
          errorTip();
        }
        onContinueClicked();
      }
    };

    const debounceInspectionSearch = _.debounce(getInspectionInfo, 1000);

    const searchInspectionItem = (code) => {
      debounceInspectionSearch(code);
    };

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.originCountry = data.data;
      }
    };

    const step3Validate = () => {
      let flag = true;
      if (formData.value.reason == 0) {
        flag = false;
        reasonErrorMessage.value = "Please select reason!";
      } else {
        reasonErrorMessage.value = "";
      }
      if (formData.value.file_upload_record_id.length == 0) {
        flag = false;
        uploadErrorMessage.value = "Please upload photos!";
      } else {
        uploadErrorMessage.value = "";
      }
      return flag;
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid && step3Validate()) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiInspection.inspectionCheckingSubmit(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                goToStep(4);
                emit("update-list");
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const goNextStep = () => {
      StepperComponent.getInstance(
        inspectionStepperRef.value as HTMLElement
      )?.goNext();
      activeStepIndex.value = StepperComponent.getInstance(
        inspectionStepperRef.value as HTMLElement
      )?.getCurrentStepIndex() as number;
      if (activeStepIndex.value == 2) {
        barcodeInputFocus();
      }
    };

    const goPrevStep = () => {
      StepperComponent.getInstance(
        inspectionStepperRef.value as HTMLElement
      )?.goPrev();
      activeStepIndex.value = StepperComponent.getInstance(
        inspectionStepperRef.value as HTMLElement
      )?.getCurrentStepIndex() as number;
      if (activeStepIndex.value == 2) {
        barcodeInputFocus();
        formData.value.reason = 0;
      } else if (activeStepIndex.value == 1) {
        inspectionInfo.value = null;
      }
    };

    const goToStep = (index) => {
      StepperComponent.getInstance(
        inspectionStepperRef.value as HTMLElement
      )?.goto(index);
      activeStepIndex.value = index;
      if (activeStepIndex.value == 2) {
        barcodeInputFocus();
      }
    };

    const onContinueClicked = () => {
      formRef.value?.validate((valid) => {
        if (valid && !disabledContinue.value) {
          goNextStep();
        }
      });
    };

    const barcodeInputFocus = () => {
      if (formData.value.ean && formData.value.origin_country) {
        console.log("barcode input focus");
        barcodeInput.value?.focus();
      }
    };

    const onPassedClicked = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          ApiInspection.inspectionCheckingSubmit({
            identifier_code: formData.value.identifier_code,
            ean: formData.value.ean,
            origin_country: formData.value.origin_country,
            file_upload_record_id: formData.value.file_upload_record_id,
          })
            .then(({ data }) => {
              loading.value = false;
              if (data.code == 0) {
                goToStep(4);
                passedTip();
                emit("update-list");
                emit("update-detail");
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const debounceScanCode = _.debounce(onPassedClicked, 1000);

    const onFailedClicked = () => {
      formRef.value?.validate((valid) => {
        if (valid) {
          goNextStep();
          warningTip();
        }
      });
    };

    const startNewScan = () => {
      goToStep(1);
      resetForm();
      barcode.value = "";
      identifierCodeRef.value?.focus();
    };

    const uploadWarningNumber = (number: number) => {
      ElNotification({
        title: t("salesOrderOverview.warning"),
        message: t("inspection.uploadImageTips", [number]),
        type: "warning",
        customClass: "warning",
        duration: 0,
      });
    };

    // const onUploadChange = async (file, files) => {
    //   uploadList.value.push(file);
    //   const params = new FormData();
    //   uploadList.value.map((item) => {
    //     params.append("file[]", new Blob([item.raw]));
    //   });
    //   uploadLoading.value = true;
    //   const { data } = await ApiInspection.uploadInspectionWarehouseImage(
    //     params
    //   );
    //   uploadLoading.value = false;
    //   if (data.code === 0) {
    //     let fileIds: Array<number> = [],
    //       arr: any[] = [],
    //       url: string[] = [];
    //     data.data.data.map((item) => {
    //       if (url.indexOf(item.url) === -1) {
    //         arr.push(item);
    //         url.push(item.url);
    //         fileIds.push(item.file_upload_record_id);
    //       }
    //     });
    //     fileList.value.splice(0, fileList.value.length, ...arr);
    //     formData.value.file_upload_record_id = fileIds;
    //   } else {
    //     showServerErrorMsg(data);
    //     handleRemove(file);
    //   }
    // };

    const onUploadChange = async (file, files) => {
      uploadList.value.push(file);
      const params = new FormData();
      let fileArr: any[] = [];
      uploadList.value.map((item) => {
        if (item.raw) {
          fileArr.push(item);
          params.append("file[]", item.raw, item.raw.name);
        }
      });
      debounceUpload(params, fileArr);
    };

    const uploadImages = async (params, files: any[]) => {
      uploadLoading.value = true;
      const { data } = await ApiInspection.uploadInspectionWarehouseImage(
        params
      );
      uploadLoading.value = false;
      if (data.code === 0) {
        let fileIds: Array<number> = [],
          arr: any[] = [],
          url: string[] = [],
          repeatNumber = 0;
        data.data.data.map((item, index) => {
          if (url.indexOf(item.url) === -1) {
            url.push(item.url);
            if (
              formData.value.file_upload_record_id.indexOf(
                item.file_upload_record_id
              ) === -1
            ) {
              fileIds.push(item.file_upload_record_id);
              arr.push(item);
            } else {
              repeatNumber++;
              uploadRef.value?.handleRemove(files[index], files[index].raw);
            }
          }
        });
        if (repeatNumber > 0) {
          uploadWarningNumber(repeatNumber);
        }
        fileList.value.push(...arr);
        formData.value.file_upload_record_id.push(...fileIds);
        uploadList.value = [];
      } else {
        showServerErrorMsg(data);
        uploadList.value = [];
        files.forEach((item) => {
          uploadRef.value?.handleRemove(item, item.raw);
        });
      }
    };

    const debounceUpload = _.debounce(uploadImages, 100);

    const onUploadError = async (error, file, files) => {
      console.log(error, file, files);
    };

    const handleRemove = (file) => {
      fileList.value.forEach((item, index) => {
        if (item.file_upload_record_id == file.file_upload_record_id) {
          fileList.value.splice(index, 1);
          uploadList.value.splice(index, 1);
        }
      });
      let fileIds: Array<number> = [];
      fileList.value.forEach((item) => {
        fileIds.push(item.file_upload_record_id);
      });
      formData.value.file_upload_record_id = fileIds;
    };

    const handlePreview = (file) => {
      let arr: string[] = [],
        number = 0;
      fileList.value.forEach((item, index) => {
        arr.push(item.url);
        if (item.file_upload_record_id == file.file_upload_record_id) {
          number = index;
        }
      });
      options.value.sources = arr;
      options.value.sourcesIndex = number;
      options.value.sourcesSlide = number + 1;
      options.value.toggler = !options.value.toggler;
    };

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      options.value.sources = previewOrderImages(previewImages);
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const handleDiscard = () => {
      hideModal(inspectionStepsModalRef.value);
    };

    const resetForm = () => {
      formData.value = {
        identifier_code: "",
        ean: "",
        origin_country: "",
        reason: 0,
        file_upload_record_id: [],
      };
      fileList.value = [];
      uploadList.value = [];
      inspectionInfo.value = null;
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        if (props.identifierCode) {
          formData.value.identifier_code = props.identifierCode;
        } else {
          identifierCodeRef.value?.focus();
        }
      }, 500);
    };

    onBeforeMount(() => {
      getCountryData();
    });

    onMounted(() => {
      getRules(1);
      modalShowListener(inspectionStepsModalRef.value, () => {
        startNewScan();
        StepperComponent.bootstrap();
        init();
      });
      modalHideListener(inspectionStepsModalRef.value, () => {
        resetForm();
      });
    });

    return {
      InspectionReason,
      props,
      loading,
      uploadLoading,
      formData,
      rules,
      options,
      formRef,
      identifierCodeRef,
      inspectionInfo,
      activeStepIndex,
      inspectionStepperRef,
      inspectionStepsModalRef,
      submitButton,
      uploadRef,
      fileList,
      uploadList,
      uploadHeaders,
      reasonErrorMessage,
      uploadErrorMessage,
      disabledContinue,
      isShowPassed,
      getPrintedSheet,
      barcode,
      barcodeInput,
      t,
      productImageSubmitForm,
      productImageUpdateList,
      closeProductImageModal,
      previewImages,
      submit,
      handleDiscard,
      resetForm,
      goNextStep,
      goPrevStep,
      onContinueClicked,
      onPassedClicked,
      onFailedClicked,
      onUploadChange,
      onUploadError,
      handlePreview,
      handleRemove,
      startNewScan,
      barcodeInputFocus,
    };
  },
});
