
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType } from "@/core/directive/type/common";
import { ApiBase, ApiInspection } from "@/core/api";
import {
  formatDateTime,
  modalShowListener,
} from "@/core/directive/function/common";
import { TaggingItem } from "@/core/directive/interface/common";
import {
  getInspectionResultBgStyleMap,
  getInspectionResultDescriptionMap,
  getInspectionResultSvgMap,
  getInspectionResultSvgStyleMap,
} from "@/core/directive/function/inspection";
import { InspectionResult } from "@/core/directive/type/inspection";
import {
  InspectionDetailInspectionItem,
  inspectionDetailInspectionItemData,
} from "@/core/directive/interface/inspection";
import moment from "moment";
import { User } from "@/store/modules/AuthModule";
import store from "@/store";
import JwtService from "@/core/services/JwtService";
import WaitingApproveImageModal from "./WaitingApproveImageModal.vue";
import { ElNotification, ElUpload } from "element-plus";

interface File {
  file_upload_record_id: number;
  size: number;
  url: string;
}

export default defineComponent({
  name: "warehouse-management-inspection-waiting-approve-modal",
  props: {
    itemData: {
      type: Object as () => InspectionDetailInspectionItem,
      default: () => {
        return Object.assign({}, inspectionDetailInspectionItemData);
      },
    },
  },
  components: { WaitingApproveImageModal },
  emits: ["update-list", "reset-form", "update-detail"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const currentInstance: any = getCurrentInstance();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const waitingApproveRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const uploadRef = ref<InstanceType<typeof ElUpload>>();
    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });
    const uploadLoading = ref<boolean>(false);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const userInfo = ref<User>();

    const formData = ref({
      images: [] as File[],
      uploadList: [] as any[],
      file_upload_record_id: [] as number[],
      radio: "",
      notes: "",
      submit: false,
    });

    const rules = ref({
      radio: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      result: [] as TaggingItem[],
      resultArr: [] as TaggingItem[],
    });

    userInfo.value = store.getters.currentUser;

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const saveInspectionPassedImage = async () => {
      uploadLoading.value = true;
      const { data } = await ApiInspection.saveInspectionPassedImage({
        id: props.itemData.id,
        file_upload_record_id: formData.value.file_upload_record_id,
      });
      uploadLoading.value = false;
      if (data.code === 0) {
        //
      } else {
        showServerErrorMsg(data);
      }
    };

    const uploadWarningNumber = (number: number) => {
      ElNotification({
        title: t("salesOrderOverview.warning"),
        message: t("inspection.uploadImageTips", [number]),
        type: "warning",
        customClass: "warning",
        duration: 0,
      });
    };

    // const onUploadChange = async (file, files) => {
    //   formData.value.uploadList.push(file);
    //   const params = new FormData();
    //   formData.value.uploadList.map((item) => {
    //     params.append("file[]", new Blob([item.raw]));
    //   });
    //   uploadLoading.value = true;
    //   const { data } = await ApiInspection.uploadInspectionWarehouseImage(
    //     params
    //   );
    //   uploadLoading.value = false;
    //   if (data.code === 0) {
    //     let isflag = false;
    //     let fileIds: Array<number> = [],
    //       arr: any[] = [],
    //       url: string[] = [];
    //     if (formData.value.file_upload_record_id.length === 0) {
    //       isflag = true;
    //     }
    //     data.data.data.map((item, index) => {
    //       if (url.indexOf(item.url) === -1) {
    //         url.push(item.url);
    //         if (
    //           formData.value.file_upload_record_id.indexOf(
    //             item.file_upload_record_id
    //           ) === -1
    //         ) {
    //           fileIds.push(item.file_upload_record_id);
    //           arr.push(item);
    //         } else {
    //           uploadRef.value?.handleRemove(files[index], files[index].raw);
    //         }
    //       }
    //     });
    //     formData.value.images.push(...arr);
    //     formData.value.file_upload_record_id.push(...fileIds);
    //     formData.value.uploadList = [];
    //     if (isflag) {
    //       saveInspectionPassedImage();
    //     }
    //   } else {
    //     showServerErrorMsg(data);
    //     formData.value.uploadList = [];
    //     files.forEach((item) => {
    //       uploadRef.value?.handleRemove(item, item.raw);
    //     });
    //   }
    // };

    const onUploadChange = async (file, files) => {
      formData.value.uploadList.push(file);
      const params = new FormData();
      let fileArr: any[] = [];
      formData.value.uploadList.map((item) => {
        if (item.raw) {
          fileArr.push(item);
          params.append("file[]", item.raw, item.raw.name);
        }
      });
      debounceUpload(params, fileArr);
    };

    const uploadImages = async (params, files: any[]) => {
      uploadLoading.value = true;
      const { data } = await ApiInspection.uploadInspectionWarehouseImage(
        params
      );
      uploadLoading.value = false;
      if (data.code === 0) {
        let isflag = false;
        let fileIds: Array<number> = [],
          arr: any[] = [],
          url: string[] = [],
          repeatNumber = 0;
        if (formData.value.file_upload_record_id.length === 0) {
          isflag = true;
        }
        data.data.data.map((item, index) => {
          if (url.indexOf(item.url) === -1) {
            url.push(item.url);
            if (
              formData.value.file_upload_record_id.indexOf(
                item.file_upload_record_id
              ) === -1
            ) {
              fileIds.push(item.file_upload_record_id);
              arr.push(item);
            } else {
              repeatNumber++;
              uploadRef.value?.handleRemove(files[index], files[index].raw);
            }
          }
        });
        if (repeatNumber > 0) {
          uploadWarningNumber(repeatNumber);
        }
        formData.value.images.push(...arr);
        formData.value.file_upload_record_id.push(...fileIds);
        formData.value.uploadList = [];
        if (isflag) {
          saveInspectionPassedImage();
        }
      } else {
        showServerErrorMsg(data);
        formData.value.uploadList = [];
        files.forEach((item) => {
          uploadRef.value?.handleRemove(item, item.raw);
        });
      }
    };

    const debounceUpload = _.debounce(uploadImages, 100);

    const onUploadError = async (error, file, files) => {
      console.log(error, file, files);
    };

    const handleRemove = (file) => {
      formData.value.images.forEach((item, index) => {
        if (item.file_upload_record_id == file.file_upload_record_id) {
          formData.value.images.splice(index, 1);
          formData.value.uploadList.splice(index, 1);
        }
      });
      let fileIds: Array<number> = [];
      formData.value.images.forEach((item) => {
        fileIds.push(item.file_upload_record_id);
      });
      formData.value.file_upload_record_id = fileIds;
    };

    const handleFilePreview = (file) => {
      let arr: string[] = [],
        number = 0;
      formData.value.images.forEach((item, index) => {
        arr.push(item.url);
        if (item.file_upload_record_id == file.file_upload_record_id) {
          number = index;
        }
      });
      previewImages(number);
    };

    const getShowInfo = async () => {
      const { data } = await ApiInspection.getInspectionWarehouseFailImage({
        id: props.itemData.id,
      });
      if (data.code == 0) {
        let fileIds: Array<number> = [],
          arr: any[] = [],
          url: string[] = [];
        data.data.images.forEach((item, index) => {
          if (url.indexOf(item.url) === -1) {
            arr.push(item);
            url.push(item.url);
            fileIds.push(item.file_upload_record_id);
          }
        });
        formData.value.images = data.data.images;
        formData.value.file_upload_record_id = fileIds;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["wms_inspection_result"],
      });
      if (data.code == 0) {
        let arr: TaggingItem[] = [];
        data.data.wms_inspection_result.items.map((item) => {
          if (Number(item.value) !== InspectionResult.CreatedBackOrder) {
            arr.push(item);
          }
        });
        options.value.result = arr;
        options.value.resultArr = data.data.wms_inspection_result.items;
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      setSubmitAttribute();
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
          setSubmitAttribute(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          submitRadio();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitRadio = async () => {
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiInspection.updateInspectionWaitingApproving({
        id: props.itemData.id,
        inspection_result: formData.value.radio,
        inspection_notes: formData.value.notes,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          emit("update-list");
          if (Number(formData.value.radio) === InspectionResult.Passed) {
            emit("update-detail");
          }
          formData.value.submit = true;
          // hideModal(waitingApproveRef.value);
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const handleDiscard = () => {
      hideModal(waitingApproveRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value.notes = "";
      formData.value.file_upload_record_id = [];
      formData.value.images = [];
      formData.value.uploadList = [];
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        getFormInfo();
        if (props.itemData.inspection_result === 0) {
          formData.value.radio = "";
          formData.value.submit = false;
        } else {
          formData.value.radio = String(props.itemData.inspection_result);
          formData.value.submit = true;
        }
      }, 0);
    };

    const customFormatDateTime = (date, format = "YYYY-MM-DD HH:mm") => {
      return formatDateTime(date, format);
    };

    const todayDate = (date = new Date()) => {
      return moment(date).format("YYYY-MM-DD HH:mm");
    };

    const getImages = computed(() => {
      let arr: string[] = [];
      formData.value.images.map((item) => {
        arr.push(item.url);
      });
      return arr;
    });

    const handlePreview = (arr: string[], index: number) => {
      currentInstance.proxy.emitter.emit("preview-images", {
        sources: arr,
        sourcesIndex: index,
        sourcesSlide: index + 1,
      });
    };

    const previewImages = (index: number) => {
      handlePreview(getImages.value, index);
    };

    onMounted(() => {
      modalShowListener(waitingApproveRef.value, () => {
        init();
      });
      modalHideListener(waitingApproveRef.value, () => {
        resetForm();
      });
      getTaggingData();
    });

    return {
      t,
      InspectionResult,
      getInspectionResultSvgMap,
      getInspectionResultSvgStyleMap,
      getInspectionResultBgStyleMap,
      getInspectionResultDescriptionMap,
      formRef,
      submitButtonRef,
      submitButton,
      waitingApproveRef,
      props,
      loading,
      uploadLoading,
      uploadHeaders,
      formData,
      uploadRef,
      rules,
      options,
      userInfo,
      getFormInfo,
      handleDiscard,
      resetForm,
      submit,
      customFormatDateTime,
      todayDate,
      onUploadChange,
      onUploadError,
      handleRemove,
      previewImages,
      handleFilePreview,
    };
  },
});
