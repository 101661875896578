
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUpdated,
  ref,
  useAttrs,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiInspection } from "@/core/api";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { formatDateTime, setModuleBCN } from "@/core/directive/function/common";
import {
  InspectionResult,
  InspectionStage,
  InspectionStatus,
} from "@/core/directive/type/inspection";
import { previewOrderImages } from "@/core/directive/function/order";
import { ProductItemImage } from "@/core/directive/interface/order";
import {
  InspectionDetail,
  inspectionDetail,
  InspectionDetailInspectionItem,
  inspectionDetailInspectionItemData,
} from "@/core/directive/interface/inspection";
import { MenuComponent } from "@/assets/ts/components";
import WaitingApprove from "./WaitingApprove.vue";
import WaitingApproveMismatch from "./WaitingApproveMismatch.vue";
import SecondTimeInspection from "./SecondTimeInspection.vue";
import InspectionStepsModal from "./InspectionStepsModal.vue";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import { PrinterConfigTaskDocumentContent } from "@/core/directive/interface/print";
import PrintPreview from "./PrintPreview.vue";
import ProductException from "./ProductException.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "warehouse-management-inspection-inspections",
  components: {
    ProductInfo,
    SkuInfo,
    WaitingApprove,
    WaitingApproveMismatch,
    SecondTimeInspection,
    InspectionStepsModal,
    PrintPreview,
    ProductException,
  },
  setup(props, context) {
    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();
    const attrs = useAttrs();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const router = useRouter();

    const detailloading = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const tableData = ref<Array<InspectionDetailInspectionItem>>([]);
    const activeIndex = ref(1);
    const inspectionStage = ref(50); // InspectionStage.FirstTimeInspection
    const inspectionStageQty = ref({});
    const inspectionDetailInspectionData = ref(
      Object.assign({}, inspectionDetailInspectionItemData)
    );

    const formData = ref({
      inspected_flag: 0,
    });

    const inspectionFormData = ref(Object.assign({}, inspectionDetail));
    const printPreviewRef = ref();

    watch(
      () => attrs.loading,
      (newValue) => {
        detailloading.value = newValue as boolean;
        const data = attrs.detailData as InspectionDetail;
        inspectionFormData.value = data;
      }
    );

    const options = ref({});

    const printProduct = (contents: PrinterConfigTaskDocumentContent[]) => {
      currentInstance.proxy.emitter.emit("all-print", contents);
    };

    const getFirstTimeInspectionData = computed(() => {
      return tableData.value.filter((item) => {
        return item.inspection_stage === InspectionStage.FirstTimeInspection;
      });
    });

    const getPrintedSheet = async (item) => {
      // item.printDisabled = true;
      // const { data } = await ApiInspection.printedSheet({
      //   identifier_code: [item.identifier_code],
      // });
      // item.printDisabled = false;
      // if (data.code === 0) {
      //   let contents: PrinterConfigTaskDocumentContent[] = [];
      //   data.data.product.map((item) => {
      //     contents.push({
      //       templateURL: data.data.templateURL,
      //       signature:
      //         "19d6f7759487e556ddcdd3d499af087080403277b7deed1a951cc3d9a93c42a7e22ccba94ff609976c5d3ceb069b641f541bc9906098438d362cae002dfd823a8654b2b4f655e96317d7f60eef1372bb983a4e3174cc8d321668c49068071eaea873071ed683dd24810e51afc0bc925b7a2445fdbc2034cdffb12cb4719ca6b7",
      //       data: item,
      //     });
      //   });
      //   printProduct(contents);
      // } else {
      //   showServerErrorMsg(data);
      // }
      const params = {
        identifier_code: [item.identifier_code],
      };
      printPreviewRef.value?.modalShow(params);
    };

    const printPreviewProduct = (params) => {
      printPreviewRef.value?.modalShow(params);
    };

    const editInspection = (item: InspectionDetailInspectionItem) => {
      inspectionDetailInspectionData.value = item;
    };

    const resetInspection = () => {
      inspectionDetailInspectionData.value = Object.assign(
        {},
        inspectionDetailInspectionItemData
      );
    };

    const inspected = async () => {
      loading.value = true;
      const { data } = await ApiInspection.updateInspectionInspected({
        id: route.params.id,
      });
      loading.value = false;
      if (data.code === 0) {
        getFromInfo();
        updateDetail();
      }
    };

    const getInspectionList = async () => {
      const { data } = await ApiInspection.getInspectionInspectionList({
        id: route.params.id,
        inspection_stage: inspectionStage.value,
      });
      if (data.code === 0) {
        data.data.items.forEach((item) => {
          item.printDisabled = false;
        });
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        formData.value.inspected_flag = data.data.inspected_flag;
        inspectionStageQty.value = data.data.inspection_stage_qty;
      }
    };

    const onNavTabClick = (index, stage) => {
      activeIndex.value = index;
      inspectionStage.value = stage;
      getFromInfo();
    };

    const updateDetail = () => {
      context.emit("getFormData");
    };

    const onQuickInspectionClick = async () => {
      Swal.fire({
        text: t("inspection.quickInspectionConfirm"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.confirm"),
        cancelButtonText: t("common.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          oneClickInspection();
        }
      });
    };

    const oneClickInspection = async () => {
      loading.value = true;
      const { data } = await ApiInspection.quickInspection({
        id: route.params.id,
      });
      loading.value = false;
      if (data.code === 0) {
        Swal.fire({
          text: t("inspection.quickInspectionSuccess"),
          icon: "success",
          showCancelButton: false,
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            getFromInfo();
            updateDetail();
          }
        });
      } else {
        getFromInfo();
        updateDetail();
        showServerErrorMsg(data);
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getInspectionList()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      currentInstance.proxy.emitter.emit("preview-images", {
        sources: previewOrderImages(previewImages),
        sourcesIndex: imageIndex,
        sourcesSlide: imageIndex + 1,
      });
    };

    onMounted(() => {
      if (!attrs.loading) {
        detailloading.value = false;
        const data = attrs.detailData as InspectionDetail;
        inspectionFormData.value = data;
      }
      setModuleBCN(t, route, router);
      getFromInfo();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      InspectionStatus,
      InspectionResult,
      InspectionStage,
      activeIndex,
      inspectionStage,
      inspectionStageQty,
      onNavTabClick,
      formatDateTime,
      detailloading,
      loading,
      tableData,
      formData,
      inspectionFormData,
      printPreviewRef,
      options,
      inspectionDetailInspectionData,
      getFirstTimeInspectionData,
      onQuickInspectionClick,
      getPrintedSheet,
      printPreviewProduct,
      editInspection,
      resetInspection,
      inspected,
      updateDetail,
      getFromInfo,
      previewImages,
    };
  },
});
