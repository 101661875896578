
import { InspectionProductException } from "@/core/directive/interface/inspection";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "warehouse-management-inspection-product-exception",
  props: {
    guidException: {
      type: Array as () => Array<string>,
      default: () => {
        return [];
      },
    },
  },
  setup(props, ctx) {
    const { t } = useI18n();
    const getException = computed(() => {
      return (item: string) => {
        let str = "";
        switch (item) {
          case InspectionProductException.BrandArticleNo:
            str = t("order.brandArticleNo");
            break;
          case InspectionProductException.EanCode:
            str = t("order.eanCode");
            break;
          case InspectionProductException.InvoiceSku:
            str = t("order.invoiceSku");
            break;
          case InspectionProductException.Material:
            str = t("order.material");
            break;
          case InspectionProductException.OriginalCountry:
            str = t("order.originalCountry");
            break;
          case InspectionProductException.PanoramaImageError:
            str = t("order.panoramaImageError");
            break;
          case InspectionProductException.Product:
            str = t("order.missingProduct");
            break;
          case InspectionProductException.Size:
            str = t("order.size");
            break;
        }
        return str;
      };
    });
    return {
      getException,
    };
  },
});
